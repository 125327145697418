import React, {useRef} from 'react';
import styles from './styles.module.scss';
import Slider from "react-slick";
import "./dots.css";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Grid, Typography} from "@material-ui/core";
import {createStyles, withStyles} from "@material-ui/core/styles";

import {RaynorGaming} from "./tab.components/raynor.gaming";
import {Outlast} from "./tab.components/outlast";
import {Tempur} from "./tab.components/tempur";
import {WorkPro} from "./tab.components/work.pro";
import {SharperTab} from "./tab.components/sharpes.image";
import {NBALeague} from "./tab.components/nba.league";
import {WoodFord} from "./tab.components/woodford";
import {Slide_1} from "./slides/slide_1";
import {Slide_2} from "./slides/slide_2";
import {Slide_3} from "./slides/slide_3";
import {Slide_4} from "./slides/slide_4";
import {MobileRaynor} from "./mobile.raynor";
import {FooterComponent} from "../../components/footer";
import ScrollContainer from 'react-indiana-drag-scroll'
import {Elizabeth} from "./tab.components/elizabeth.sutton";
import {ComfortRevolution} from "./tab.components/comfort.revolution";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface RaynorTabsProps {
}

interface TabProps {
    label: any;
    onClick?: any;
}

const numOfTabs = 8;

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div className={`${styles.desktop_panel} `}
             hidden={value !== index}
             id={`scrollable-prevent-tabpanel-${index}`}
             {...other}
        >
            {children}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const TabsComponent = withStyles({
    root: {
        //position: 'absolute',
        overflowX: 'scroll',
        width: '100%',
        height: '100px',
    },
    indicator: {
        display: 'none'
    },
})((props: RaynorTabsProps) => <Tabs scrollButtons="off" variant="scrollable" {...props} />);

const TabButton = withStyles(() => createStyles({
        root: {
            color: '#6B6E73',
            filter: 'grayscale(100%)',
            opacity: '0.3',
            height: '80px',
            margin: '10px 0',
            width: '100px',
            paddingRight: '20px'
        }
    })
)((props: TabProps) => <Tab {...props} />);

const RaynorTab = withStyles(() => createStyles({
        root: {
            padding: '10px 30px',
            backgroundColor: '#D13532',
            opacity: '1',
            height: '100px',
            width: '260px',
        }
    })
)((props: TabProps) => <Tab {...props} />);

const EurotechTab = withStyles(() => createStyles({
        root: {
            padding: '10px 30px',
            height: '100px',
            width: '260px',
            borderLeft: '1px solid rgba(0,0,0, 0.1)',
        },
    })
)((props: TabProps) => <Tab disableRipple {...props} />);


export const RaynorPage = () => {
    const [value, setValue] = React.useState(0);
    const [currentBlock, setCurrentBlock] = React.useState(0);
    const [readyToScroll, setReadyToScroll] = React.useState(true);
    const [showFooter, setShowFooter] = React.useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<{}> | any, newValue: number) => {
        newValue !== 10 && setValue(newValue);
        setShowFooter(false);
    };

    const onClickChange = (newValue: number) => {
        newValue !== 10 && setValue(newValue);
    }

    const slider = useRef<any>(null);

    const wheelHelper = (event: React.WheelEvent<{}>) => {
        const isScrolledDown = event.deltaY > 25;
        const isScrolledUp = event.deltaY < -25;

        if (readyToScroll) {
            if (isScrolledDown) {
                setReadyToScroll(false);
                if (!value) {
                    currentBlock == 2 ? setShowFooter(true) : setShowFooter(false);
                }
                if (!value) {
                    if (currentBlock == 3) {
                        setShowFooter(true);
                    }
                    slider.current.slickNext();
                } else if (value < numOfTabs - 1) {
                    setValue(value + 1);
                    setShowFooter(true);
                }
                setTimeout(() => {
                    setReadyToScroll(true);
                }, 800);
            } else {
                if (isScrolledUp) {
                    setReadyToScroll(false);
                    if (value) {
                        setValue(value - 1);
                        setShowFooter(false);
                    } else {
                        slider.current.slickPrev();
                        setShowFooter(false);
                    }
                    setTimeout(() => {
                        setReadyToScroll(true);
                    }, 800);
                }
            }
        }
    };

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: false,
        //adaptiveHeight: true,
        className: styles.slider_container,
        arrows: false,
        dotsClass: "vertical_dots",
        afterChange: (currentSlide: any) => {
            setCurrentBlock(currentSlide);
        }
    };
    const tabSliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Grid container className={styles.wrapper}>

            <Grid className={styles.desktop_layout}>
                <Grid item md={12} className={styles.panel}>
                    <RaynorTab
                        classes={{root: value === 0 ? styles.raynor_group_selected : styles.raynor_group}}
                        onClick={() => onClickChange(0)}
                        label={<img src='./assets/images/raynorgroup/rayanor_logo_new.png'/>}
                        {...a11yProps(0)}/>
                    <TabsComponent classes={{root: styles.tabs}} aria-label="scrollable prevent tabs example">
                        <ScrollContainer className={styles.scroll}>
                            <TabButton
                                classes={{root: value === 1 ? styles.raynor_gaming_selected : styles.raynor_gaming}}
                                onClick={() => handleChange(null, 1)}
                                label={<img src='./assets/images/raynorgroup/Raynor_Gaming_Logo.png'/>}
                                {...a11yProps(1)}/>
                            <TabButton classes={{root: value === 2 ? styles.logotype_selected : styles.logotype}}
                                       onClick={() => handleChange(null, 2)}
                                       label={<img src='./assets/images/raynorgroup/Outlast_Logo.png'/>}
                                       {...a11yProps(2)}/>
                            <TabButton classes={{root: value === 3 ? styles.logotype_selected : styles.logotype}}
                                       onClick={() => handleChange(null, 3)}
                                       label={<img src='./assets/images/raynorgroup/Tempur-Pedic_Logo.png'/>}
                                       {...a11yProps(3)}/>
                            {/*<TabButton classes={{root: value === 4 ? styles.work_pro_selected : styles.work_pro}}*/}
                            {/*           onClick={() => handleChange(null, 4)}*/}
                            {/*           label={<img src='./assets/images/raynorgroup/WORKPRO_logo.png'/>}*/}
                            {/*           {...a11yProps(4)}/>*/}
                            {/*<TabButton*/}
                            {/*    classes={{root: value === 7 ? styles.logotype_woodford_selected : styles.logotype_woodford}}*/}
                            {/*    onClick={() => handleChange(null, 7)}*/}
                            {/*    label={<div><img src='./assets/images/raynorgroup/Wodford_Reserve_Logo.png'/>*/}
                            {/*    </div>}*/}
                            {/*    {...a11yProps(7)}/>*/}
                            <TabButton classes={{root: value === 5 ? styles.logotype_selected : styles.logotype}}
                                       onClick={() => handleChange(null, 5)}
                                       label={<img src='./assets/images/raynorgroup/tabs/nba/nba_logo.png'/>}
                                       {...a11yProps(5)}/>
                            <TabButton classes={{root: value === 6 ? styles.logotype_selected : styles.logotype}}
                                       onClick={() => handleChange(null, 6)}
                                       label={<img src='./assets/images/raynorgroup/tabs/sharper/sharper_logo.png'/>}
                                       {...a11yProps(6)}/>
                            <TabButton classes={{root: value === 8 ? styles.elizabeth_selected : styles.elizabeth}}
                                       onClick={() => handleChange(null, 8)}
                                       label={<img
                                           src='./assets/images/raynorgroup/tabs/elizabeth/elizabeth_logo.png'/>}
                                       {...a11yProps(8)}/>
                            <TabButton classes={{root: value === 9 ? styles.logotype_selected : styles.logotype}}
                                       onClick={() => handleChange(null, 9)}
                                       label={<img src='./assets/images/raynorgroup/tabs/comfort/comfort_logo.png' style={{maxHeight: '50px'}}/>}
                                       {...a11yProps(9)}/>
                        </ScrollContainer>
                    </TabsComponent>

                    <EurotechTab classes={{root: styles.eurotech}}
                                 onClick={() => handleChange(null, 10)}
                                 label={
                                     <a href="https://eurotechseating.com/" target='_blank' className={styles.img}>
                                         <img src='./assets/images/raynorgroup/eurotech11.png' alt=""/>
                                     </a>
                                 }
                                 {...a11yProps(10)}/>
                </Grid>

                <Grid item md={12} className={styles.content_wrapper}>
                    <div style={value !== 0 ? {visibility: 'hidden'} : {visibility: 'visible'}} onWheel={wheelHelper}>
                        <TabPanel value={value} index={0}>
                            <Slider {...sliderSettings} ref={slider}>
                                <Slide_1/>
                                <Slide_2/>
                                <Slide_3/>
                                <Slide_4/>
                            </Slider>
                        </TabPanel>
                    </div>

                    <TabPanel value={value} index={1}>
                        <RaynorGaming/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Outlast/>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Tempur/>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <WorkPro/>
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                        <WoodFord/>
                    </TabPanel>

                    {/*//TODO NEW TABS*/}
                    <TabPanel value={value} index={5}>
                        <NBALeague/>
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <SharperTab/>
                    </TabPanel>
                    <TabPanel value={value} index={8}>
                        <Elizabeth/>
                    </TabPanel>
                    <TabPanel value={value} index={9}>
                        <ComfortRevolution/>
                    </TabPanel>

                </Grid>

                <Grid item md={12}>
                    <FooterComponent show={showFooter}/>
                </Grid>
            </Grid>

            <Grid item md={12} className={styles.mobile_layout}>
                <MobileRaynor/>
            </Grid>


        </Grid>
    )
};
