import React from "react";
import {Grid, useTheme} from "@material-ui/core";
import styles from './styles.module.scss';
import {createStyles, withStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import {ReaynorTabComponent} from "./tab.components/raynor.group";
import {RaynorGamingMobile} from './tab.components/raynor.gaming/';
import {OutlastMobile} from "./tab.components/outlast";
import {TempurModile} from "./tab.components/tempur";
import {WorkProMobile} from "./tab.components/work.pro";
import {WoodFordMobile} from "./tab.components/woodford";
import {NbaLeague} from "./tab.components/nba.league";
import {SharperTab} from "./tab.components/sharper.image";
import {Elizabeth} from "./tab.components/elizabeth.sutton";
import {ComfortRevolution} from "./tab.components/comfort.revolution";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface RaynorTabProps {
    label: any;
}

interface RaynorTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    let className = styles.active;
    if (value !== index) {
        className = styles.unactive;
    }
    return (
        <div className={`${styles.mobile_panel} ${className}`} /*hidden={value !== index}*/>
            {children}
        </div>
    );
}

const RaynorTabs = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '60px',
    },
    indicator: {
        display: 'none'
    },
})((props: RaynorTabsProps) => <Tabs scrollButtons="off" variant="scrollable" {...props} />);

const RaynorTab = withStyles(() =>
    createStyles({
        root: {
            padding: '10px 20px',
            filter: 'grayscale(100%)',
            opacity: '0.5',
            height: '60px',
        },
        selected: {
            filter: 'grayscale(0%)',
            opacity: '1'
        }
    })
)((props: RaynorTabProps) => <Tab disableRipple {...props} />);


export const MobileRaynor = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}> | any, newValue: number) => {
        newValue !== 10 && setValue(newValue);
    };

    return (
        <Grid container className={styles.wrapper}>

            <Grid container onClick={() => handleChange(null, 0)} className={styles.raynor_wrapper}>
                <img src='./assets/images/raynorgroup/rayanor_logo_new.png'/>
            </Grid>

            <Grid container className={styles.panel_wrapper}>
                {/*// TODO Refactoring header*/}
                <RaynorTabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <RaynorTab classes={{root: styles.raynor_group}}
                               label={<img src='./assets/images/raynorgroup/rayanor_logo_new.png'/>}/>
                    <RaynorTab classes={{root: styles.logotype}}
                               label={<img src='./assets/images/raynorgroup/Raynor_Gaming_Logo.png' alt=""/>}/>
                    <RaynorTab classes={{root: styles.logotype}}
                               label={<img src='./assets/images/raynorgroup/Outlast_Logo.png' alt=""/>}/>
                    <RaynorTab classes={{root: styles.logotype}}
                               label={<img src='./assets/images/raynorgroup/Tempur-Pedic_Logo.png' alt=""/>}/>
                    {/*<RaynorTab classes={{root: styles.logotype}}*/}
                    {/*           label={<img src='./assets/images/raynorgroup/WORKPRO_logo.png' alt=""/>}/>*/}
                    {/*<RaynorTab classes={{root: styles.logotype}}*/}
                    {/*           label={<div><img src='./assets/images/raynorgroup/Wodford_Reserve_Logo.png' alt=""/>*/}
                    {/*           </div>}/>*/}

                    <RaynorTab classes={{root: styles.logotype}}
                               label={<img src='./assets/images/raynorgroup/tabs/nba/nba_logo.png' alt=""/>}/>
                    <RaynorTab classes={{root: styles.logotype}}
                               label={<img src='./assets/images/raynorgroup/tabs/sharper/sharper_logo.png' alt=""/>}/>
                    <RaynorTab classes={{root: styles.logotype}}
                               label={<img src='./assets/images/raynorgroup/tabs/elizabeth/elizabeth_logo.png' alt=""/>}/>
                    <RaynorTab classes={{root: styles.logotype}}
                               label={<img src='./assets/images/raynorgroup/tabs/comfort/comfort_logo.png' alt=""/>}/>

                    <RaynorTab classes={{root: styles.eurotech}}
                               label={<div>
                                   <a href="https://eurotechseating.com/" target='_blank'>
                                       <img src='./assets/images/raynorgroup/eurotech11.png' alt=""/>
                                   </a>
                               </div>}/>
                </RaynorTabs>
            </Grid>
            <Grid item md={12} className={styles.content_wrapper}>
                {/*<SwipeableViews*/}
                {/*    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}*/}
                {/*    index={value}*/}
                {/*    onChangeIndex={handleChangeIndex}*/}
                {/*>*/}
                <TabPanel value={value} index={0}>
                    <ReaynorTabComponent/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <RaynorGamingMobile/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <OutlastMobile/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <TempurModile/>
                </TabPanel>
                {/*<TabPanel value={value} index={4}>*/}
                {/*    <WorkProMobile/>*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={value} index={5}>*/}
                {/*    <WoodFordMobile/>*/}
                {/*</TabPanel>*/}
                <TabPanel value={value} index={4}>
                    <NbaLeague/>
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <SharperTab/>
                </TabPanel>
                <TabPanel value={value} index={6}>
                    <Elizabeth/>
                </TabPanel>
                <TabPanel value={value} index={7}>
                    <ComfortRevolution/>
                </TabPanel>
                {/*</SwipeableViews>*/}
            </Grid>

        </Grid>
    )
}